.OCVBlogContainerRow {
    min-height: 60%;
}
.OCVBlogContainerPaperOne {
    padding: 20px;
    margin-bottom: 15px;
}
.OCVBlogContainerExternalDiv {
    display: table;
    width: 100%;
}
.OCVBlogContainerInternalDivOne {
    display: table-cell;
    width: 28%;
    vertical-align: top;
    padding-bottom: 0;
}
.OCVBlogContainerNestedDivOne {
    display: block;
    border: 4px solid #01291c;
    position: relative;
    overflow: hidden;
}
.OCVBlogContainerNestedImg {
    width: 100%;
    position: absolute;
    top: 0; 
    bottom: 0; 
    left: 0; 
    right: 0; 
    margin: auto;
}
.OCVBlogContainerInternalDivTwo {
    display: table-cell;
    width: 72%;
    vertical-align: top;
    padding-left: 20px;
}
.OCVBlogContainerSpan {
    display: block;
    margin-bottom: 20px;
}
.OCVBlogContainerNestedDivTwo {
    padding-bottom: 0;
}
.OCVBlogContainerLinkOne {
    color: #003227;
}
.OCVBlogContainerLinkTwo {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 40px 40px 40px 0px;
    padding-left: 20px;
}
.OCVBlogContainerCol {
    margin: 20px 0px;
}
.OCVBlogContainerPaperTwo {
    padding: 20px;
}