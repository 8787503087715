@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:200,300,400,600,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
.OCVVideoPlayerDiv {
    position: relative;
    padding-top: 56.25%;
}
.OCVBlogSliderImg {
    height: auto;
    object-fit: contain;
}
.OCVBlogSliderButton {
    width: 25px;
    height: 25px;
    padding: 0 !important;
}
.HorizontalSublayoutModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 100;
}
.HorizontalSublayoutModalContent {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 300px;
}
.HorizontalSublayoutCloseButton {
    position: absolute !important;
    right: 1em !important;
    top: 0.1em !important;
    color: grey;
    padding: 0 !important;
}
.HorizontalSublayoutImg {
    padding-bottom: 10px;
    margin: auto;
    display: block;
}
.HorizontalSublayoutModalTitle {
    font-size: 1.5em;
}
.HorizontalSublayoutIcon {
    color: white;
}
.HorizontalSublayoutDiv {
    background-color: #0063A8;
    color: white;
}
@media (max-width: 425px) {
    .HorizontalSublayoutModalTitle {
        font-size: 1.2em;
    }
    .HorizontalSublayoutIcon {
        height: 0.8em;
    }
}
.TopBarModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 100;
}
.TopBarModalContent {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 300px;
}
.TopBarCloseButton {
    position: absolute !important;
    right: 1em !important;
    top: 0.1em !important;
    color: grey;
    padding: 0 !important;
}
.TopBarImageView {
    padding-top: 10px;
    margin: auto;
    display: block;
}
.TopBarDiv {
    background-color: #222;
    color: white;
}
.TopBarModalTitle {
    font-size: 1.5em;
}
.TopBarIcon {
    color: white;
}
@media (max-width: 425px) {
    .TopBarModalTitle {
        font-size: 1.2em;
    }
    .TopBarIcon {
        height: 0.8em;
    }
}
.NavSubmenuModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 100;
}
.NavSubmenuModalContent {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 300px;
}
.NavSubmenuCloseButton {
    position: absolute !important;
    right: 1em !important;
    top: 0.1em !important;
    color: grey;
    padding: 0 !important;
}
.NavSubmenuImg {
    padding-bottom: 10px;
    margin: auto;
    display: block;
}
.NavSubmenuDiv {
    background-color: #222;
    color: white;
}
.NavSubmenuModalTitle {
    font-size: 1.5em;
}
.NavSubmenuIcon {
    color: white;
}
@media (max-width: 425px) {
    .NavSubmenuModalTitle {
        font-size: 1.2em;
    }
    .NavSubmenuIcon {
        height: 0.8em;
    }
}
.NavBarModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 100;
}
.NavBarModalContent {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 300px;
}
.NavBarCloseButton {
    position: absolute !important;
    right: 1em !important;
    top: 0.1em !important;
    color: grey;
    padding: 0 !important;
}
.NavBarImg {
    padding-bottom: 10px;
    margin: auto;
    display: block;
}
.NavBarListItem {
    width: auto;
}
.NavBarDiv {
    background-color: #222;
    color: white;
}
.NavBarModalTitle {
    font-size: 1.5em;
}
.NavBarIcon {
    color: white;
}
@media (max-width: 425px) {
    .NavBarModalTitle {
        font-size: 1.2em;
    }
    .NavBarIcon {
        height: 0.8em;
    }
}
.SliderDiv {
    background-color: #000;
    height: 400px;
}
.FeatureModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 100;
}
.FeatureModalContent {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 300px;
}
.FeatureCloseButton {
    position: absolute !important;
    right: 1em !important;
    top: 0.1em !important;
    color: grey;
    padding: 0 !important;
}
.FeatureImageOne {
    padding-bottom: 10px;
    margin: auto;
    display: block;
}
.FeatureImageTwo {
    display: block;
}
.FeatureInternalDiv {
    background-color: #222;
    color: white;
}
.FeatureModalTitle {
    font-size: 1.5em;
}
.FeatureIcon {
    color: white;
}
@media (max-width: 425px) {
    .FeatureIcon {
        height: 0.8em;
    }
    .FeatureModalTitle {
        font-size: 1.2em;
    }
}
.featureBarDiv {
    text-align: center;
}
.OCVPageExternalDiv {
    background-color: #000;
    height: 400px;
}
.OCVPageColOne {
    padding-bottom: 30px;
}
.OCVPageImage {
    display: block;
    overflow: hidden;
    max-width: 100%;
    margin: auto;
}
.OCVPageColTwo {
    padding-top: 50px;
}
.OCVPagePaper {
    padding: 20px;
    margin-bottom: 15px;
}
.OCVPageDiv {
    padding-bottom: 30px;
}
.OCVBlogContainerRow {
    min-height: 60%;
}
.OCVBlogContainerPaperOne {
    padding: 20px;
    margin-bottom: 15px;
}
.OCVBlogContainerExternalDiv {
    display: table;
    width: 100%;
}
.OCVBlogContainerInternalDivOne {
    display: table-cell;
    width: 28%;
    vertical-align: top;
    padding-bottom: 0;
}
.OCVBlogContainerNestedDivOne {
    display: block;
    border: 4px solid #01291c;
    position: relative;
    overflow: hidden;
}
.OCVBlogContainerNestedImg {
    width: 100%;
    position: absolute;
    top: 0; 
    bottom: 0; 
    left: 0; 
    right: 0; 
    margin: auto;
}
.OCVBlogContainerInternalDivTwo {
    display: table-cell;
    width: 72%;
    vertical-align: top;
    padding-left: 20px;
}
.OCVBlogContainerSpan {
    display: block;
    margin-bottom: 20px;
}
.OCVBlogContainerNestedDivTwo {
    padding-bottom: 0;
}
.OCVBlogContainerLinkOne {
    color: #003227;
}
.OCVBlogContainerLinkTwo {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 40px 40px 40px 0px;
    padding-left: 20px;
}
.OCVBlogContainerCol {
    margin: 20px 0px;
}
.OCVBlogContainerPaperTwo {
    padding: 20px;
}
.OCVBlogDetailColOne {
    padding-bottom: 30px;
}
.OCVBlogDetailColTwo {
    padding-top: 50px;
    padding-bottom: 30px;
}
.OCVBlogDetailPaper {
    padding: 20px;
    margin-bottom: 15px;
}
.OCVBlogDetailSpan {
    display: block;
    margin-bottom: 20px;
}
.OCVBlogDetailImg {
    max-width: 100%;
    max-height: 100%;
}
.OCVFormComponentsDiv {
    padding: 10px 0px;
}
.OCVFormComponentsSpan {
    color: red;
}
.OCVFormPaperOne {
    padding: 10px;
    margin-bottom: 15px;
}
.OCVFormDiv {
    height: 400px;
}
.OCVFormRow {
    padding: 30px 0px;
}
.OCVFormPaperTwo {
    padding: 10px;
    margin-bottom: 15px;
    line-height: 400px;
    height: 400px;
}
.OCVFormH2 {
    text-align: center;
    vertical-align: middle;
    display: inline-block;
}
.OCVContactsEntryPaper {
    padding: 20px; 
    margin-bottom: 15px;
    min-height: 400px;
}
.OCVContactsEntryAddress {
    display: block;
}
.OCVContactsDiv {
    height: 400px;
}
.columnA {
    position: relative;
    padding: 10px 0px;
}
.columnB {
    position: relative;
    padding: 10px 20px;
}
.poweredBy {
    display: inline;
    padding: 5px 0px;
}
.phoneNumberCardDiv {
    padding: 30px 40px;
    border: 1px solid #FFF;
    border-radius: 3px;
    text-align: center;
}
.col2div {
    padding-top: 40px;
    padding-bottom: 20px;
    text-align: center;
}
.complexFooterRowOne {
    padding: 60px 0;
    color: #FFF;
}
.complexFooterRowTwo {
    position: relative;
}
.footerImgOne {
    max-height: 40px;
}
.footerImgTwo {
    position: absolute;
    left: 0;
    margin: 0 auto;
    right: 0;
    display: block;
    top: -40px;
    max-height: 80px;
    text-align: center;
}
.footerColOne {
    width: auto;
}
.footerColTwo {
    line-height: 1.6em;
    text-align: center;
    margin: 40px 0;
}
.footerAnchorOne {
    color: #FFF;
}
.footerAnchorTwo {
    display: block;
    font-weight: 700;
    font-size: 1.3em;
    padding: 5px 0;
}
.footerList {
    margin: auto;
    display: block;
}
.footerH6 {
    display: inline;
    padding: 5px 0px;
}
.footerInfo {
    margin: 0;
}
.footerParagraph {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1.2em;
    letter-spacing: 1px;
}
.footerNavLink {
    color: #FFF;
    padding: 0 10px;
    font-size: 1em;
    letter-spacing: 1px;
    font-weight: 600;
}
.ViewOurAppBadgeStyle {
    margin: auto;
    max-width: 180px;
    display: inline-block;
    border-radius: 5px;
    height: auto;
    max-height: 59px;
}
.ViewOurAppColOne {
    padding-top: 50px;
}
.ViewOurAppPaperOne {
    padding: 20px;
    margin-bottom: 15px;
}
.ViewOurAppImgOne {
    border-radius: 8px;
    display: block;
    margin: auto;
}
.ViewOurAppH1 {
    text-align: center;
}
.ViewOurAppColTwo {
    text-align: center;
}
.ViewOurAppAnchor {
    max-width: 180px;
    display: inline-block;
}
.ViewOurAppImgTwo {
    margin: auto;
    display: block;
}
.ViewOurAppPaperTwo {
    margin: 10px 0;
    padding: 10px;
    min-height: 150px;
}
.ViewOurAppH5 {
    display: inline;
    padding: 5px;
}
.ViewOurAppPaperThree {
    margin: 10px 0;
    text-align: left;
    padding: 10px;
    min-height: 150px;
}
.breadcrumbRow {
    padding: 60px 0;
    background-color: #F5F5F5;
}
.GoogleSearchCol {
    padding-top: 50px;
}
.GoogleSearchPaper {
    min-height: 475px;
    padding: 20px;
    margin-bottom: 15px;
}
.OCVCalendarRow {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.OCVCalendarRowMiddle {
    align-items: center;
}
.OCVCalendarCol {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
}
.OCVCalendarColStart {
    justify-content: flex-start;
    text-align: left;
}
.OCVCalendarColCenter {
    justify-content: center;
    text-align: center;
}
.OCVCalendarColEnd {
    justify-content: flex-end;
    text-align: right;
}
.OCVCalendar {
    display: block;
    position: relative;
    width: 100%;
    background: #FFF;
    border: 1px solid #eee;
}
.OCVCalendarHeader {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 115%;
    padding: 1.5em 0;
    border-bottom: 1px solid #eee;
}
.OCVCalendarDays {
    text-transform: uppercase;
    font-weight: 400;
    color: #ccc;
    font-size: 70%;
    padding: .75em 0;
    border-bottom: 1px solid #eee;
}
.OCVCalendarSelected {
    border-left: 10px solid transparent;
    border-image: linear-gradient(45deg, #1a8fff 0%,#53cbf1 40%);
    border-image-slice: 1;
}
.OCVCalendarNumber {
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    top: .75em;
    right: .75em;
    font-weight: 700;
}
.OCVCalendarDisabled {
    color: #ccc;
    pointer-events: none;
}
.OCVCalendarNumEvents {
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    bottom: .75em;
    left: .75em;
    font-weight: 700;
    color: #FFF;
    padding: 5px;
    background-color: #1a8fff;
    border-radius: 8px;
    width: 50%;
}
.OCVCalendarEvent {
    padding: 10px;
    margin: 15px;
}
.OCVCalendarCloseButton {
    position: absolute !important;
    right: 1em !important;
    top: 1em !important;
    color: grey;
    padding: 0 !important;
}
.OCVMapViewDiv {
    background: white;
    border: 1px solid #ccc;
    padding: 15;
}
.OCVMapListDiv {
    overflow-y: scroll;
    max-height: 70vh;
}
.OCVMapListPaper {
    padding: 10px;
    margin: 10px;
}
.OCVMapListH6 {
    color: grey;
}
.OCVMapDetailViewPaper {
    padding: 10px;
    margin: 10px;
}
.OCVMapDetailViewH6 {
    color: grey;
}
.OCVMapDetailViewAnchor {
    padding: 0 5px;
}
.OCVMapDetailViewCol {
    text-align: left;
    padding: 10px 0;
}
.OCVMapDetailViewParagraph {
    padding: 15px 0;
}
.OCVMapDetailViewRow {
    justify-content: flex-start;
}
.OCVMapDetailLinks {
    color: black;
}
@media (max-width: 768px) {
    .OCVMapDetailLinks {
        color: #0056b3;
        text-decoration: underline;
    }
}
.OCVMapToolbarExternalDiv {
    box-shadow: 0 2px 2px -2px gray;
}
.OCVMapToolbarInternalDiv {
    margin: 10px 0;
    padding: 10px 0;
}
.OCVMapToolbarNestedDivOne {
    padding: 5px 0px;
    float: right;
}
.OCVMapToolbarButton {
    margin: 0px 5px;
}
.OCVMapToolbarColOne {
    background-color: #eee;
}
.OCVMapToolbarColTwo {
    padding: 5px;
}
.OCVMapToolbarNestedDivTwo {
    padding: 5px 0;
}
.OCVMapCol {
    padding: 0;
}
.OCVMapDivOne {
    height: 100%;
}
.OCVMapDivTwo {
    height: 105vh;
    overflow: hidden;   
}
.TwoColumnViewColOne {
    padding: 20px 0;
    display: block;
    margin: 0 auto;
}
.TwoColumnViewColTwo {
    margin: 40px 0 20px;
}
.TwoColumnViewHeader {
    font-weight: 600;
    line-height: 2;
    margin-bottom: 30px;
}
.TwoColumnViewParagraph {
    margin-bottom: 30px;
    line-height: 1.65;
    font-size: 1.3em;
}
.OCVSubmenuPageCol {
    padding-top: 50px;
}
.OCVSubmenuPagePaper {
    padding: 20px;
    margin-bottom: 15px;
}
.OCVSubmenuPageListItem {
    margin: 10px 0;
}
.OCVAlertsContainerColOne {
    padding: 20px;
    margin: 20px 0px;
}
.OCVAlertsContainerPaperOne {
    padding: 20px;
    margin-bottom: 15px;
}
.OCVAlertsContainerExternalDiv {
    display: table;
    width: 100%;
}
.OCVAlertsContainerInternalDiv {
    display: table-cell;
    width: 72%;
    vertical-align: top;
    padding-left: 20px;
}
.OCVAlertsContainerSpanOne {
    display: block;
    margin-bottom: 20px;
}
.OCVAlertsContainerNestedDiv {
    padding-bottom: 0;
}
.OCVAlertsContainerLink {
    color: #003227;
    margin: 10px 0;
}
.OCVAlertsContainerSpanTwo {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 35px 40px 35px 0px;
    padding-top: 10px;
}
.OCVAlertsContainerColTwo {
    padding: 20px;
    margin: 20px 0px;
}
.OCVAlertsContainerPaperTwo {
    padding: 20px;
    text-align: center;
}
.OCVAlertsContainerColThree {
    padding: 20px;
    margin: 20px 0px;
}
.OCVAlertsContainerPaperThree {
    padding: 20px;
}
.OCVAlertsDetailColOne {
    padding-top: 50px;
    padding-bottom: 30px;
}
.OCVAlertsDetailButton {
    margin-bottom: 10px;
}
.OCVAlertsDetailPaper {
    padding: 30px;
    margin-bottom: 15px;
    min-height: 350px;
}
.OCVAlertsDetailSpanOne {
    display: block;
    margin-bottom: 20px;
}
.OCVAlertsDetailSpanTwo {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 35px 40px 45px 0px;
    padding-top: 10px;
}
.OCVAlertsDetailColTwo {
    padding-bottom: 30px;
}
.LayoutsContainer {
    padding: 0;
    overflow-x: auto;
}
html, body {
  margin: 0 !important;
  padding: 0;
  background-color: #fefefe;
    color: #222;
  font-family: -apple-system, BlinkMacSystemFont, "Montserrat","Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Open Sans", serif;
}
.block {
  display: block;
}
.react-datepicker-popper {
  z-index: 2;
}

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker__input-container {
  display: block;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loading-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.arrowLeft {
  position: absolute;
  top: 45%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
}

.arrowRight {
  position: absolute;
  top: 45%;
  width: 27px;
  right: 10px;
  height: 44px;
  margin-top: 22px;
  z-index: 10;
  cursor: pointer;
}

.row{
  margin:0;
  justify-content: center;
  width: 100%;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    width: auto;
}

ul.pagination {
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
ul.pagination li.page-item.active a.page-link {
  color: #fff !important;
  background-color: #222 !important;
  border-color: #ced4da !important;
}
ul.pagination a.page-link {
  padding: 0.75rem 1rem;
  min-width: 3.5rem;
  text-align: center;
  box-shadow: none !important;
  border-color: #ced4da !important;
  color: #222;
  font-weight: 900;
  font-size: 1rem;
}
ul.pagination a.page-link:hover {
  background-color: #f4f4f4;
}

.formSlider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
}

.formSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #222;
  cursor: pointer;
}

.formSlider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #222;
  cursor: pointer;
}

.smartbanner-top {
  position: relative !important;
}
