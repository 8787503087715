.TwoColumnViewColOne {
    padding: 20px 0;
    display: block;
    margin: 0 auto;
}
.TwoColumnViewColTwo {
    margin: 40px 0 20px;
}
.TwoColumnViewHeader {
    font-weight: 600;
    line-height: 2;
    margin-bottom: 30px;
}
.TwoColumnViewParagraph {
    margin-bottom: 30px;
    line-height: 1.65;
    font-size: 1.3em;
}