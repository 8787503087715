.OCVBlogDetailColOne {
    padding-bottom: 30px;
}
.OCVBlogDetailColTwo {
    padding-top: 50px;
    padding-bottom: 30px;
}
.OCVBlogDetailPaper {
    padding: 20px;
    margin-bottom: 15px;
}
.OCVBlogDetailSpan {
    display: block;
    margin-bottom: 20px;
}
.OCVBlogDetailImg {
    max-width: 100%;
    max-height: 100%;
}